import Link from "next/link";

import Image from "@/components/Image";

const Footer = () => {
  return (
    <footer className="footer mt-auto py-3">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-auto">
            <Image
              src="/static/main/images/kvrx-logo-yellow.png"
              width={120}
              height={120}
              layout="fixed"
              alt="KVRX Logo"
            />

            <p className="text-muted">
              <small>
                The University of Texas at Austin
                <br />
                Site Content ©2011‐2021 Texas Student Media
              </small>
            </p>
          </div>
          <div className="col">
            <div className="row row-cols-2">
              <div className="col">
                <ul className="nav flex-column">
                  <h6>
                    <b>Links</b>
                  </h6>
                  <li className="nav-item mb-2">
                    <a
                      href="http://www.supportstudentvoices.org#kvrx"
                      className="nav-link p-0"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Donate
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a
                      href="http://utexas.rent/"
                      target="_blank"
                      rel="noreferrer"
                      className="nav-link p-0"
                    >
                      Housing
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a
                      href="https://utlists.utexas.edu/sympa/subscribe/kvrx-love"
                      target="_blank"
                      rel="noreferrer"
                      className="nav-link p-0"
                    >
                      Lovelist
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a
                      href="https://publicfiles.fcc.gov/fm-profile/KVRX"
                      target="_blank"
                      rel="noreferrer"
                      className="nav-link p-0"
                    >
                      FCC Public File
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <Link href="/login">
                      <a className="nav-link p-0">Login</a>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="nav flex-column">
                  <h6>
                    <b>
                      <a
                        href="http://www.tsm.utexas.edu/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Texas Student Media
                      </a>
                    </b>
                  </h6>
                  <li className="nav-item mb-2">
                    <a
                      href="http://sites.utexas.edu/cactus/"
                      target="_blank"
                      rel="noreferrer"
                      className="nav-link p-0"
                    >
                      Cactus Yearbook
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a
                      href="http://www.dailytexanonline.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="nav-link p-0"
                    >
                      The Daily Texan
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a
                      href="http://watchtstv.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="nav-link p-0"
                    >
                      TSTV
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a
                      href="http://texastravesty.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="nav-link p-0"
                    >
                      Texas Travesty
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
